<template>
  <b-modal
    id="confirm-close-modal"
    size="md"
    hide-header
    hide-footer
    body-class="edition-body"
    header-class="edition-header"
  >
    <div class="modal-header">
      <div class="modal-title">Deseja confirmar saída?</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <p class="p-3">
      Você está saindo sem salvar as mudanças, deseja salvar as alterações
      feitas?
    </p>

    <div class="my-4 d-flex justify-content-end">
      <b-button variant="outline-primary" class="mr-3" @click="closeModal">
        Sair sem salvar
      </b-button>

      <b-button variant="primary" @click="saveAccountData">
        Salvar dados
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ConfirmCloseNfModal',
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  methods: {
    saveAccountData() {
      this.$emit('saveAccountData')
      this.$bvModal.hide('confirm-close-modal')
    },
    closeModal() {
      this.$bvModal.hide('confirm-close-modal')
      this.$emit('closeModal')
    }
  }
}
</script>
<style lang="scss">
#confirm-close-modal {
  .edition-header {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans';
  }

  .edition-body {
    font-size: 16px;
    font-weight: 400;
    color: var(--type-active);
  }
}
</style>
